<template>
	<Header />
	<AboutUs />
	<ExampleDeals />
	<Contact />
	<Footer />
</template>
<script>
import Header from '@/components/Header';
import AboutUs from '@/components/AboutUs';
import ExampleDeals from '@/components/ExampleDeals';
import Contact from '@/components/Contact';
import Footer from '@/components/Footer';

export default {
	name: 'Home',
	components: {
		Header,
		AboutUs,
		ExampleDeals,
		Contact,
		Footer,
	},
};
</script>
