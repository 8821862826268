<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="py-16 bg-white overflow-hidden lg:py-24">
    <div class="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
      <svg
        class="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-blue-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)"
        />
      </svg>

      <div class="relative">
        <h2
          class="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl"
        >
          Property investment examples
        </h2>
        <p class="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
          Let us show you some real investment examples so you can see how a the
          deals work.
        </p>
      </div>

      <div
        class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center"
      >
        <div class="relative">
          <h3
            class="text-2xl font-bold text-gray-900 tracking-tight sm:text-3xl"
          >
            Family Let in Scarborough
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            Investment money is used for all up front costs including deposit,
            legal fees, refurbishment and tenant find fees.
          </p>

          <div class="mt-8 overflow-hidden">
            <dl class="-mx-8 -mt-8 flex flex-wrap">
              <div class="flex flex-col px-8 pt-8">
                <dt class="order-2 text-base font-medium text-gray-500">
                  Total Investment
                </dt>
                <dd
                  class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                >
                  £42,719
                </dd>
              </div>
              <div class="flex flex-col px-8 pt-8">
                <dt class="order-2 text-base font-medium text-gray-500">
                  Annual Rental Yield
                </dt>
                <dd
                  class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                >
                  7.5%
                </dd>
              </div>
              <div class="flex flex-col px-8 pt-8">
                <dt class="order-2 text-base font-medium text-gray-500">
                  Purchase Price
                </dt>
                <dd
                  class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                >
                  £120,000
                </dd>
              </div>
            </dl>
            <dl class="-mx-8 flex flex-wrap">
              <div class="flex flex-col px-8 pt-8">
                <dt class="order-2 text-base font-medium text-gray-500">
                  Discount (Asking Price)
                </dt>
                <dd
                  class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                >
                  7.7%
                </dd>
              </div>
              <div class="flex flex-col px-8 pt-8">
                <dt class="order-2 text-base font-medium text-gray-500">
                  ROI - After Refinance *
                </dt>
                <dd
                  class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                >
                  24.2%
                </dd>
              </div>
            </dl>
          </div>
          <div class="mt-4 font-bold text-sm text-blue-500">
            * Assume release 75% of discount back (no growth)
          </div>
        </div>

        <div class="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
          <img
            class="relative mx-auto"
            width="490"
            src="../assets/example-1.jpeg"
            alt=""
          />
        </div>
      </div>

      <svg
        class="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect
              x="0"
              y="0"
              width="4"
              height="4"
              class="text-blue-200"
              fill="currentColor"
            />
          </pattern>
        </defs>
        <rect
          width="404"
          height="784"
          fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)"
        />
      </svg>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div
          class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center"
        >
          <div class="lg:col-start-2">
            <h3
              class="text-2xl font-bold text-gray-900 tracking-tight sm:text-3xl"
            >
              Family Let in Scarborough
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              Investment money is used for all up front costs including deposit,
              legal fees, refurbishment and tenant find fees.
            </p>

            <div class="mt-8 overflow-hidden">
              <dl class="-mx-8 -mt-8 flex flex-wrap">
                <div class="flex flex-col px-8 pt-8">
                  <dt class="order-2 text-base font-medium text-gray-500">
                    Total Investment
                  </dt>
                  <dd
                    class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                  >
                    £41,278
                  </dd>
                </div>
                <div class="flex flex-col px-8 pt-8">
                  <dt class="order-2 text-base font-medium text-gray-500">
                    Annual Rental Yield
                  </dt>
                  <dd
                    class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                  >
                    7.6%
                  </dd>
                </div>
                <div class="flex flex-col px-8 pt-8">
                  <dt class="order-2 text-base font-medium text-gray-500">
                    Purchase Price
                  </dt>
                  <dd
                    class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                  >
                    £115,000
                  </dd>
                </div>
              </dl>
              <dl class="-mx-8 flex flex-wrap">
                <div class="flex flex-col px-8 pt-8">
                  <dt class="order-2 text-base font-medium text-gray-500">
                    Discount (Asking Price)
                  </dt>
                  <dd
                    class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                  >
                    8%
                  </dd>
                </div>
                <div class="flex flex-col px-8 pt-8">
                  <dt class="order-2 text-base font-medium text-gray-500">
                    ROI - After Refinance *
                  </dt>
                  <dd
                    class="order-1 text-2xl font-extrabold text-blue-500 sm:text-3xl"
                  >
                    24.3%
                  </dd>
                </div>
              </dl>
            </div>
            <div class="mt-4 font-bold text-sm text-blue-500">
              * Assume release 75% of discount back (no growth)
            </div>
          </div>

          <div class="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
            <img
              class="relative mx-auto"
              width="490"
              src="../assets/example-2.jpeg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ExampleDeals',
};
</script>
