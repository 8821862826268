<template>
	<div class="">
		<router-view />
	</div>
</template>

<script>
export default {
	name: 'App',
};
</script>
