<template>
  <div class="bg-gray-50">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
      <div class="space-y-12">
        <h2 class="text-3xl font-bold tracking-tight sm:text-4xl">
          Meet the team
        </h2>

        <ul
          role="list"
          class="space-y-12 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
        >
          <li>
            <div
              class="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8"
            >
              <div
                class="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4"
              >
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="../assets/nat.jpg"
                  alt=""
                />
              </div>
              <div class="sm:col-span-2">
                <div class="space-y-4">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3>Natalie Beswick</h3>
                    <p class="text-blue-500">Director | Operations Manager</p>
                  </div>
                  <div class="text-lg">
                    <p class="text-gray-500">
                      Natalie has been Operations Manager for a property
                      investment company for around 5 years. With experience in
                      all sides of the business from lettings to the done for
                      you service and now in the property education business.
                      With a passion for property, Natalie decided to embark on
                      her own property journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div
              class="space-y-4 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8"
            >
              <div
                class="h-0 aspect-w-3 aspect-h-2 sm:aspect-w-3 sm:aspect-h-4"
              >
                <img
                  class="object-cover shadow-lg rounded-lg"
                  src="../assets/nick.jpg"
                  alt=""
                />
              </div>
              <div class="sm:col-span-2">
                <div class="space-y-4">
                  <div class="text-lg leading-6 font-medium space-y-1">
                    <h3>Nick Beswick</h3>
                    <p class="text-blue-500">Director | Web Developer</p>
                  </div>
                  <div class="text-lg">
                    <p class="text-gray-500">
                      Nick has worked in the web development industry for eight
                      years. He has always had a keen interest in property and
                      has gained knowledge & education to get more experience on
                      how to grow a property portfolio safely and securely.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
        <p class="font-semibold text-center">
          Together, we are on our property journey not only grow our own
          portfolio but to help investors do the same.
        </p>
      </div>
    </div>
  </div>
</template>

<script setup></script>

<script>
export default {
  name: 'AboutUs',
};
</script>
